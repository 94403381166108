import { FALLBACK_ADDITIONAL_MILEAGE_FEE } from '@finn/ua-constants';
import { getForBusinessCookie } from '@finn/ui-modules';
import { cn, twoDecimalPlaces } from '@finn/ui-utils';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import get from 'lodash/get';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import VehicleContext from '~/contexts/Vehicle';
import useFormattedNumber from '~/hooks/useFormattedNumber';
import { getDistancePrices } from '~/utils/pdp';

import { useConfigureStore } from '../ConfigurePageStore';
import DistanceOptionsItem from './DistanceOptionsItem';

type Price = { distance: number; price: number; duration: number };

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.up('sm')]: {
      borderBottom: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    '& > span': {
      marginLeft: 10,
    },
  },
}));

const DistanceOptions: React.FunctionComponent<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const classes = useStyles();
  const i18n = useIntl();
  const isForBusiness = getForBusinessCookie();
  const { vehicle } = useContext(VehicleContext);
  const { setKilometerPackage, kilometerPackage } = useConfigureStore();

  const vehiclePrice = get(vehicle, 'downpayment_prices', {}) as Record<
    string,
    number
  >;

  const distancePrices: Price[] = getDistancePrices(
    vehiclePrice,
    isForBusiness
  );

  const handleClickOnOption = React.useCallback(
    (option) => {
      setKilometerPackage(option.distance);
    },
    [setKilometerPackage]
  );

  const extraUnitPrice = useFormattedNumber(
    twoDecimalPlaces(
      get(vehicle, 'price.extra_km_price', FALLBACK_ADDITIONAL_MILEAGE_FEE)
    )
  );

  const distanceUnit = i18n.formatMessage({ id: 'general.distanceUnit' });

  const title = i18n.formatMessage({
    id: 'pdp.mileagePerMonth',
  });

  const disclaimer = i18n.formatMessage(
    {
      id: `pdp.mileagePackageDisclaimer.b2c`,
    },
    { extraUnitPrice }
  );

  const disclaimerTooltip = i18n.formatMessage({
    id: 'pdp.mileagePackageDisclaimerTooltip',
  });

  return (
    <div className={classes.root} translate="no">
      <h6
        className={cn(
          classes.title,
          children ? 'global-t5-semibold' : 'global-t6-semibold'
        )}
      >
        {title}
      </h6>

      {children}
      <Grid container spacing={1}>
        {distancePrices.map((option: Price) => {
          return (
            <Grid item xs={6} key={option.distance}>
              <DistanceOptionsItem
                monthlyPrice={option}
                distanceUnit={distanceUnit}
                onClick={handleClickOnOption}
                isSelected={option.distance === kilometerPackage}
              />
            </Grid>
          );
        })}
      </Grid>

      <p className="body-14-light mt-4">
        {disclaimer} {disclaimerTooltip}
      </p>
    </div>
  );
};

export default React.memo(DistanceOptions);
