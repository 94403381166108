import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { Button } from '@finn/ui-components';
import { makeStyles, Typography } from '@material-ui/core';
import { useRouter } from 'next/router';
import React, { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { PLPRoute } from '~/types/general';

const useStyles = makeStyles((theme) => ({
  mb: {
    marginBottom: theme.spacing(4),
  },
}));

type Props = {
  vehicle?: GenericVehicleDetails;
  onClose(): void;
};

const NotifySuccess: React.FC<Props> = ({ vehicle, onClose }) => {
  const classes = useStyles();
  const i18n = useIntl();

  const router = useRouter();

  const handleCtaClick = useCallback(() => {
    onClose();
    router.push(PLPRoute.SUBSCRIPTION);
  }, [onClose, router]);

  const title = i18n.formatMessage({
    id: `general.notifyMeModal.comingSoon.successTitle`,
  });

  const description = i18n.formatMessage(
    { id: `general.notifyMeModal.comingSoon.successDescription` },
    {
      location: null,
      car: vehicle ? `${vehicle.brand?.id} ${vehicle.model}` : '',
    }
  );

  const ctaLabel = i18n.formatMessage({
    id: `general.notifyMeModal.comingSoon.successCtaLabel`,
  });

  return (
    <>
      <Typography variant="h3" className={classes.mb}>
        {title}
      </Typography>

      <Typography variant="body1" className={classes.mb}>
        {description}
      </Typography>

      <div>
        <Button
          label={ctaLabel}
          color="primary"
          data-cy="browse-more"
          onClick={handleCtaClick}
        />
      </div>
    </>
  );
};

export default memo(NotifySuccess);
