import { SkeletonNode } from '@finn/atoms';
import { ProductOptionCard } from '@finn/platform-modules';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { InfoToolTip } from '@finn/ui-components';
import { getForBusinessCookie } from '@finn/ui-modules';
import { getFormattedPrice, getTermLabel } from '@finn/ui-utils';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { useTrackingData } from '~/components/ProductDetails/utils/useTrackingData';
import VehicleContext, { OneTimePrice } from '~/contexts/Vehicle';
import { getTermsPrices } from '~/utils/pdp';

import { useConfigureStore } from '../ConfigurePageStore';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& > span': {
      marginLeft: 10,
    },
  },
}));

interface IProps {
  className?: string;
}

const ContractDuration: React.FunctionComponent<
  React.PropsWithChildren<IProps>
> = ({ className, children }) => {
  const classes = useStyles();

  const i18n = useIntl();

  const isExperimentDownPayment = useIsABVariant(
    Features.ExpFakedoorDownpayment
  );

  const { vehicle } = useContext(VehicleContext);

  const { setTerm, term, downPayment } = useConfigureStore();

  const isForBusiness = getForBusinessCookie();

  const downPaymentAmount = vehicle.downpayment_prices.downpayment_fixed_amount;

  const durations = getTermsPrices(
    vehicle,
    isForBusiness ? 'b2b' : 'b2c',
    downPayment,
    isExperimentDownPayment,
    downPaymentAmount
  );

  const tooltipContent = i18n.formatMessage({
    id: 'checkout.pageData.term_tooltip_content',
  });

  const isDisplayTooltip = durations.some(
    (duration) => duration.duration === 1
  );

  const cardCols = durations.length > 1 ? 6 : 12;

  const flexTermLabel = i18n.formatMessage({
    id: 'checkout.pageData.flex_term_label',
  });

  const contractTermLabel = i18n.formatMessage({
    id: 'pdp.contractTerm',
  });

  const trackingData = useTrackingData();

  const trackDurationChange = useCallback(
    (duration: string) => {
      PDPTracking.contractTermClicked({ ...trackingData, term: duration });
    },
    [trackingData]
  );

  const onChangeDuration = useCallback(
    (oneTimeValue: OneTimePrice) => {
      trackDurationChange(String(oneTimeValue.duration));

      setTerm(oneTimeValue.duration);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [trackDurationChange]
  );

  return (
    <div className={className} translate="no">
      <div className={classes.title}>
        <h6
          className={`${children ? 'global-t5-semibold' : 'global-t6-semibold'}`}
        >
          {contractTermLabel}
        </h6>

        {isDisplayTooltip && (
          <span>
            <InfoToolTip content={tooltipContent} />
          </span>
        )}
      </div>
      {children}

      <Grid container item spacing={1}>
        {durations.map((oneTimeValue) => (
          <Grid item xs={cardCols} key={oneTimeValue.duration}>
            <ProductOptionCard
              title={getTermLabel(oneTimeValue.duration, flexTermLabel, i18n)}
              onClick={() => onChangeDuration(oneTimeValue)}
              descComponent={
                <SkeletonNode loading={false}>
                  <div className="body-14-regular my-1">
                    {getFormattedPrice(i18n, oneTimeValue.price, 0)}
                  </div>
                </SkeletonNode>
              }
              isSelected={term === oneTimeValue.duration}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ContractDuration;
