import { useConfigureStore } from '@finn/auto-ui/components/ConfigurePage';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { GenericVehicleDetails, VehicleContext } from '@finn/ua-vehicle';
import { useCalculatePrice } from '@finn/ui-modules';
import { useContext } from 'react';
// @todo check if we can get this from the configure store
export function useMonthlySubscriptionCost(
  initVehicle?: GenericVehicleDetails
) {
  const { vehicle } = useContext(VehicleContext);
  const { term, kilometerPackage, downPayment } = useConfigureStore();
  const isFakedoorDownpayment = useIsABVariant(Features.ExpFakedoorDownpayment);
  const downPaymentAmount = vehicle.downpayment_prices.downpayment_fixed_amount;

  const priceParams = {
    vehicle: vehicle || initVehicle,
    term,
    kilometerPackage,
    downPaymentAmount,
    isDownPayment: downPayment,
    isFakedoorDownpayment,
  };

  const monthlyCost = useCalculatePrice(priceParams);

  const total = monthlyCost;

  return { monthlyCost, total };
}
